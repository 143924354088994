.nav_wrapper {
    text-align: center;
    overflow: hidden;
    min-height: 50px;
    font-weight: bold;
    .nav_link {
        a {
            height: 100%;
            .icon_wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                background: inherit;
            }
            .labelStyling {
                text-align: left;
            };
        };
        &:hover, a[aria-selected="true"] {
            background : #eee;
        };
    };
};
.dropDownWrapper {
    .dropDownMenuToggle {
        border : 0;
        background-color: #eee;
        color : #474747;
        display: flex;
        font-weight: bold;
        &[aria-expanded="true"] {
            .menuIcon {
                transform : rotate(180deg);
            };
        };
        .menuIcon {
            transition: transform 250ms;
        };
        .selectionFont {
            width: 30px;
            height: 30px;
            // background: #1d2124;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: inherit;
        };
        .icon {
            width : 30px;
            height : 30px;
        };
        .selection {
            flex : 1;
        }
        &:hover, &:focus {
            background-color: inherit;
            color : inherit;
            border : 0;
            box-shadow: none;
        };
        &::after {
            // text-align: right;
            display: none !important;
        };
    };
    .menuItemWrapper {
        text-align: center;
        .menu {
            &:focus {
                background-color: #fff;
                color : #474747;
            }
            .icon {
                width : 30px;
                height : 30px;
            };
            .label {
                flex : 1;
                text-align: left;
            };
        };
    };
};