.about_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
};
.content {
    transition : all 250ms;
    .description_wrapper {
        min-height: 200px;
    };
    .about_description {
        margin : 0.25em;
        .about_me {
            margin : 1em 0;
        }
        .profile {
            min-width: 100px;
            padding : 0;
            margin : 0 1em;
            shape-outside: circle(50%);
            
            .pic {
                box-shadow: 0px -3px 7px #474747;
                width : 100%;
                height : auto;
            };
        };
    };
    .skillset {
        width: 100%;
        font-size : 0.9em;
        margin : 1em 0 0 0;
        .skillset_tab_wrapper {
            width: 100%;
            max-width: 800px;  
            box-shadow: 0px -1px 5px #bbb;
        };
    };
};

@media screen and (max-width:380px) {
    .about_description {
        text-align: center;
        .profile {
            justify-content: center;
            width : 100% !important;
            margin : 0 !important;
            padding : 0 !important;
            float : none !important;
            max-width : 100% !important;
            .pic {
                width : 100px !important;
            };
        };
    };
};