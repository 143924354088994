.skillset_content {
    flex : 1;
    background-color: #eee;
    padding : 1em;
    .skillset_list {
        min-height : 30px;
        margin : 0.2em 0;
        .badges {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height : 30px;
            margin : 0.2em;
            font-size: 1em;
            padding : 0 1em;
        };
    };
    .skillset_description {
        margin : 0.2em 0;
    };
    p {
        margin : 0;
    }
    a {
        color : #007bff;
        text-decoration: underline;
    }
};