div#root {
    position : absolute;
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
    background : #fff;
    font-family: 'Source Code Pro', monospace;
    color : #474747;
}

div#root div#content-wrapper {
    position: relative;
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
    
    display : flex;
    flex-direction: column;

    margin : auto;
    padding : 5px;
}

div#root>div#overlay {
    position : absolute;
    width : 100%;
    height : 100%;
    top : 0;
    left : 0;
    display : flex;
    justify-content: center;
    align-items: center;
}

div#root div#top-bar {
    position : fixed;
    top : 0;
    left : 0;
    width : 100%;
    height : 80px;
    display: flex;
    flex-direction: row;
    z-index : 1;
    background: #fff;
    padding : 0 0.5em;
}

div#top-bar div#menu-wrapper {
    width : 100%;
    max-width: 1250px;
    margin : auto;
};

div#top-bar div#name {
    max-width : 200px;
    width : 100%;
    margin : 5px;
}

div#top-bar div#logo-bar {
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    text-align: center;
    font-family: 'Source Code Pro', monospace;
    justify-content: center;
    align-items: center;

    margin : 2.5px;
}

div#design-menu {
    font-size: 1.8em;
    color : black;
    text-align: center;
}

span#orange {
    color : #dfb317;
    font-weight : bold;
}

span#purple {
    color : rgb(206, 19, 206);
    font-weight: bold;
}

span#green {
    color : green;
    font-weight: bold;
}

span#cyan {
    color : #3ac6f5;
    font-weight : bold;
}

span#blue {
    color : blue;
    font-weight: bold;
}

span#red {
    color : #f00;
    font-weight: bold;
}

span#greenish {
    display: inline-block;
    color : #6eb490;
    font-weight: bold;
}


div#design-menu {
    font-size: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

div#design-menu-footer {
    font-size: 2.5em;
    display : inline;
}

a, a:hover{
    color : inherit;
    text-decoration: inherit;
}

div#this-is-small {
    font-size: 0.5em;
    text-align: left;
    font-weight: bold;
}

div#menu-wrapper {
    display: flex;
    flex : 1;
    height : 100%;
    justify-content: flex-end;
}

a.menu-link {
    margin: 5px;
    padding-right: 1.05rem;
    color: rgba(0, 0, 0, 0.5);
    transition: color 250ms;
}

a.menu-link:hover, a.menu-link.selected {
    color: #474747;
    font-weight: bold;
}

div#information-wrapper {
    position: relative;
    z-index : 0;
    margin : 0;
    padding: 80px 15px 60px 15px;
    width: 100%;
}

div#information-wrapper div.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
};

.navbar-collapse {
    background: #fff;
}

.content-appear div#heading-wrapper div#heading div#overlay {
    left : 0;
    width : 100%;
}

.content-appear div#heading-wrapper {
    opacity: 0;
}

.content-appear.content-appear-active div#heading-wrapper {
    opacity : 1;
    transition: opacity 500ms;
}

.content-appear.content-appear-active div#heading-wrapper div#heading div#overlay {
    left : 101%;
    width: 0;
    transition: left 500ms, width 500ms;
}

.content-exit div#heading-wrapper div#heading div#overlay {
    opacity : 1;
    transition : opacity 500ms;
}

footer {
    position : fixed;
    bottom : 0;
    left : 0;
    width : 100%;
    height : 60px;
    padding : 0 0.5em;
    background-color: rgba(255, 255, 255, 0.8);
}

footer div#design-menu-footer {
    display : flex;
    width: 100%;
    max-width: 1250px;
    margin : auto;
}

footer div#design-menu-footer div#design-contact {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}