.heading_wrapper {
    width : 100%;
    height : 100%;
    .heading {
        position: relative;
        width: 100%;
        display : inline-block;
        text-align: center;
        .overlay {
            position: absolute;
            height : 30px;
            background : rgba(0, 0, 0, 1);
        }
    }
}